import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-6 mb-20 flex flex-col gap-8" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UICheckstep = _resolveComponent("UICheckstep")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.checkSteps, (v, key) => {
      return (_openBlock(), _createBlock(_component_UICheckstep, {
        key: key,
        label: key,
        value: v
      }, null, 8, ["label", "value"]))
    }), 128))
  ]))
}