
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  components: {
    PDEIcon,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
}
